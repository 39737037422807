import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { SessionService } from '@common/services/session.service';

@Injectable({
    providedIn: 'root',
})
export class AdminGuard implements CanActivate {
    constructor(private sessionService: SessionService, private router: Router) { }

    public async canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Promise<boolean> {
        const session = this.sessionService.getSession();

        if (!session || session?.user?.role !== 'admin') {
            await this.router.navigateByUrl('/', { state: { redirectUrl: state.url } });
            return false;
        } else {
            return true;
        }
    }
}
