<ng-container *ngIf="sessionService.user$ | async as user">
  <button
    *ngIf="sessionService.role$ | async as role"
    mat-button
    [matMenuTriggerFor]="menu"
    [attr.aria-label]="role + ' menu'"
  >
    {{ userName$ | async }}
    <mat-icon>arrow_drop_down</mat-icon>
  </button>
  <mat-menu #menu="matMenu">
    <a
      *ngIf="(rolesService.isSeekerProvider$ | async) === false"
      mat-menu-item
      [routerLink]="linksService.homeRoute$ | async"
    >
      <mat-icon svgIcon="home"></mat-icon>
      Home
    </a>
    <a *ngIf="rolesService.isAdmin$ | async" mat-menu-item routerLink="/admin/assets">
      <mat-icon svgIcon="assets"></mat-icon>
      Assets
    </a>
    <ng-container
      *ngIf="(rolesService.isProviderOrSeeker$ | async) || (rolesService.isSeekerProvider$ | async)"
    >
      <a *ngIf="user$ | async as user" mat-menu-item [routerLink]="'/profile/' + user.id">
        <mat-icon svgIcon="user"></mat-icon>
        My Profile
      </a>
    </ng-container>
    <a
      *ngIf="rolesService.isProvider$ | async"
      mat-menu-item
      [routerLink]="'/institutions/' + user.institution"
    >
      <mat-icon svgIcon="bank"></mat-icon>
      My Institution
    </a>
    <ng-container *ngIf="rolesService.isAdmin$ | async">
      <button [matMenuTriggerFor]="institutions" type="button" mat-menu-item>
        <mat-icon svgIcon="bank"></mat-icon>
        Institutions
      </button>
      <mat-menu #institutions="matMenu">
        <a mat-menu-item routerLink="/admin/institutions">All Institutions</a>
        <a mat-menu-item routerLink="/admin/institutions/new">
          <mat-icon svgIcon="plus"></mat-icon>
          Create New Institution
        </a>
      </mat-menu>
      <button [matMenuTriggerFor]="users" type="button" mat-menu-item>
        <mat-icon svgIcon="people"></mat-icon>
        Users
      </button>
      <mat-menu #users="matMenu">
        <a mat-menu-item routerLink="/admin/users">All Users</a>
        <a mat-menu-item routerLink="/admin/users/new">
          <mat-icon svgIcon="plus"></mat-icon>
          Create New User
        </a>
      </mat-menu>
      <a mat-menu-item routerLink="/admin/connections">
        <mat-icon svgIcon="connect-mini"></mat-icon>
        Connections
      </a>
      <a mat-menu-item routerLink="/admin/requests">
        <mat-icon color="primary">launch</mat-icon>
        Access Requests
      </a>
    </ng-container>
    <a *ngIf="rolesService.isProviderOrSeeker$ | async" mat-menu-item routerLink="/connections">
      <mat-icon svgIcon="connect-mini"></mat-icon>
      Connections
    </a>
    <ng-container *ngIf="rolesService.isAdmin$ | async">
      <a mat-menu-item routerLink="/admin/agreements">
        <mat-icon svgIcon="list-italic"></mat-icon>
        T&Cs
      </a>
      <a mat-menu-item routerLink="/admin/deleted-assets">
        <mat-icon svgIcon="delete"></mat-icon>
        Deleted Assets
      </a>
      <a mat-menu-item routerLink="/admin/advertisement">
        <mat-icon svgIcon="ads"></mat-icon>
        Advertisement
      </a>
    </ng-container>
    <a *ngIf="rolesService.isProvider$ | async" mat-menu-item routerLink="/deleted-assets">
      <mat-icon svgIcon="delete"></mat-icon>
      Deleted Assets
    </a>
    <a
      *ngIf="rolesService.isSeeker$ | async"
      mat-menu-item
      routerLink="/seeker"
      [queryParams]="{ isTracked: 'true' }"
    >
      <mat-icon svgIcon="star-bordered"></mat-icon>
      Tracked Assets
    </a>
    <a
      *ngIf="
        (sessionService.user$ | async)?.isSeekerProvider || (rolesService.isSeekerProvider$ | async)
      "
      mat-menu-item
      routerLink="/seeker-provider"
    >
      <mat-icon svgIcon="transfer"></mat-icon>
      Change Role
    </a>
    <a
      *ngIf="
        (sessionService.user$ | async)?.isSeekerProvider ||
        (rolesService.isProviderOrSeeker$ | async) ||
        (rolesService.isSeekerProvider$ | async)
      "
      mat-menu-item
      routerLink="/user-support"
    >
      <mat-icon svgIcon="chat-question"></mat-icon>
      User Support
    </a>
    <button mat-menu-item (click)="logout()">
      <mat-icon svgIcon="logout"></mat-icon>
      Log Out
    </button>
  </mat-menu>
</ng-container>
